import fetch from '../utils/fetch'

export function getTradeList(query) {
return fetch({
url: '/trade',
method: 'get',
params: query
})
}

export function setPaymentSetting(query) {
return fetch({
url: '/trade/payment/setting',
method: 'post',
data: query
})
}

export function getPaymentSetting(query) {
return fetch({
url: '/trade/payment/setting',
method: 'get',
params: query
})
}

/**
 * @param {*} cancelToken 用于频繁切换取消请求
*/
export function getOrderList(query, cancelToken) {
return fetch({
url: '/orders',
method: 'get',
params: query,
cancelToken,
})
}


export function getRightsList(query) {
return fetch({
url: '/rights/getdata',
method: 'get',
params: query
})
}

export function findRightsList(query) {
return fetch({
url: '/rights/list',
method: 'get',
params: query
})
}

export function createRights(query) {
return fetch({
url: '/rights',
method: 'post',
data: query
})
}

export function getOrderDetail(orderId, query) {
  return fetch({
    url: '/order/' + orderId, method: 'get', params: query
  })
}
// 发货包裹信息
export function getDeliveryLists(query) {
  return fetch({
  url: '/delivery/lists',
  params: query,
  method: 'get',

  })
  }
export function getRightsLogList(query) {
return fetch({
url: '/rights/log',
method: 'get',
params: query
})
}

export function exportList(query) {
return fetch({
url: '/rights/logExport',
method: 'get',
params: query,
})
}

export function delayRights(query) {
return fetch({
url: '/rights/delay',
method: 'post',
data: query
})
}

export function transferRights(query) {
  return fetch({
    url: '/transfer/rights',
    method: 'put',
    params: query
  })
}

export function transferRightsList(query) {
  return fetch({
    url: '/transfer/rights/list',
    method: 'get',
    params: query
  })
}

export function getRightsInfo(query) {
return fetch({
url: '/rights/info',
method: 'get',
params: query,
})
}

export function delivery(query) {
  return fetch({
    url: '/delivery',
    method: 'post',
    data: query
  })
}

export function updateDelivery(order_id, query) {
  return fetch({
    url: '/delivery/' + order_id,
    method: 'put',
    data: query
  })
}

export function updateRemark(order_id, query) {
  return fetch({
    url: '/order/remark/' + order_id,
    method: 'put',
    data: query
  })
}

export function updateFreightFee(order_id, query) {
  return fetch({
    url: '/order/freightfee/' + order_id,
    method: 'put',
    data: query
  })
}

export function orderExport(query) {
  return fetch({
    url: '/orders/exportdata',
    method: 'get',
    params: query,
  })
}

export function orderNormalExport(query) {
  return fetch({
    url: '/orders/exportnormaldata',
    method: 'get',
    params: query,
  })
}

export function rightsExport(query) {
  return fetch({
    url: '/rights/exportdata',
    method: 'get',
    params: query,
  })
}

export function getPaymentList() {
  return fetch({
    url: '/trade/payment/list',
    method: 'get'
  })
}

export function tradeExport(query) {
  return fetch({
    url: '/trades/exportdata',
    method: 'get',
    params: query,
  })
}

export function ExportLogList(query) {
  return fetch({
    url: '/espier/exportlog/list',
    method: 'get',
    params: query,
  })
}

export function ExportLogFileDown(query) {
  return fetch({
    url: '/espier/exportlog/file/down',
    method: 'get',
    params: query,
  })
}

export function getOrderSetting(query) {
  return fetch({
    url: '/orders/setting/get',
    method: 'get',
    params: query,
  })
}

export function setOrderSetting(query) {
  return fetch({
    url: '/orders/setting/set',
    method: 'post',
    data: query,
  })
}

export function getCancelOrderInfo(order_id, query) {
  return fetch({
    url: '/order/'+order_id+'/cancelinfo',
    method: 'get',
    params: query
  })
}

export function processDrugOrders(order_id, query) {
  return fetch({
    url: '/order/'+order_id+'/processdrug',
    method: 'put',
    data: query
  })
}

export function cancelConfirm(order_id, query) {
  return fetch({
    url: '/order/'+order_id+'/confirmcancel',
    method: 'post',
    data: query
  })
}

export function cancelOrderConfirm(order_id, query) {
    return fetch({
        url: '/order/'+order_id+'/cancel',
        method: 'post',
        data: query
    })
}
export function OmsQueueLogList(query) {
  return fetch({
    url: '/omsqueuelog',
    method: 'get',
    params: query
  })
}

export function OmsSkuLogList(query) {
  return fetch({
    url: '/omsinventorylog',
    method: 'get',
    params: query
  })
}

export function getCartMarketingAd(query) {
  return fetch({
    url: '/orders/cartad/get',
    method: 'get',
    params: query,
  })
}

export function setCartMarketingAd(query) {
  return fetch({
    url: '/orders/cartad/set',
    method: 'post',
    data: query,
  })
}

export function getLogisticsList () {
  return fetch({
    url: '/trade/logistics/list',
    method: 'get'
  })
}

export function setKdniaoSetting (query) {
  return fetch({
    url: '/trade/kuaidi/setting',
    method: 'post',
    data: query
  })
}

export function getKdniaoSetting (query) {
  return fetch({
    url: '/trade/kuaidi/setting',
    method: 'get',
    params: query
  })
}

export function seSfbspSetting (query) {
  return fetch({
    url: '/trade/sfbsp/setting',
    method: 'post',
    data: query
  })
}

export function getSfbspSetting (query) {
  return fetch({
    url: '/trade/sfbsp/setting',
    method: 'get',
    params: query
  })
}

export function getDeliveryDetail (query) {
  return fetch({
    url: '/delivery/details',
    method: 'get',
    params: query
  })
}

export function getRefundErrorLogsList (query) {
  return fetch({
    url: '/trade/refunderrorlogs/list',
    method: 'get',
    params: query
  })
}
export function getTradeRateList (query) {
  return fetch({
    url: '/trade/rate',
    method: 'get',
    params: query
  })
}

export function refundResubmit (id) {
  return fetch({
    url: '/trade/refunderrorlogs/resubmit/' + id,
    method: 'put'
  })
}

export function refundResubmitManual (id) {
  return fetch({
    url: '/trade/refunderrorlogs/resubmitmanual/' + id,
    method: 'put'
  })
}

export function replyTradeRate (query) {
  return fetch({
    url: '/trade/rate',
    method: 'put',
    params: query
  })
}

export function getTradeRateDetails (rate_id) {
  return fetch({
    url: '/trade/'+rate_id+'/rate',
    method: 'get'
  })
}

export function deleteRate (id) {
  return fetch({
    url: '/trade/rate/'+id,
    method: 'delete'
  })
}

export function isBind () {
  return fetch({
    url: '/third/saascert/isbind',
    method: 'get'
  })
}

export function exportInvoice (query) {
  return fetch({
    url: '/invoice/exportdata',
    method: 'get',
    params: query,
  })
}

export function isInvoiced (query) {
  return fetch({
    url: '/invoice/invoiced',
    method: 'get',
    params: query,
  })
}

export function exportSalesReport (query) {
  return fetch({
    url: '/financial/salesreport',
    method: 'get',
    params: query,
  })
}

export function getWriteoff (order_id) {
  return fetch({
    url: '/writeoff/'+order_id,
    method: 'get'
  })
}

export function doWriteoff (order_id,query) {
  return fetch({
    url: '/writeoff/'+order_id,
    method: 'post',
    data: query
  })
}


export function getPickupcode (order_id) {
  return fetch({
    url: '/pickupcode/'+order_id,
    method: 'get'

  })
}

export function getProcessLog(order_id, query) {
  return fetch({
    url: '/order/process/' + order_id, method: 'get', params: query
  })
}

export function getTeadeSetting () {
  return fetch({
    url: '/trade/setting',
    method: 'get'
  })
}

export function setTradeSetting (data) {
  return fetch({
    url: '/trade/setting',
    method: 'post',
    data: data
  })
}

export function getPayOrderInfo (order_id, pay_type) {
  return fetch({
    url: '/order/payorderinfo/' + order_id + '?pay_type=' + pay_type,
    method: 'get'
  })
}

export function getRefundOrderInfo (refund_bn, pay_type) {
  return fetch({
    url: '/order/refundorderinfo/' + refund_bn + '?pay_type=' + pay_type,
    method: 'get'
  })
}

export function getAdapayDetail (query) { // 支付配置信息查询
  return fetch({
    url: '/adapay/payment/setting',
    method: 'get',
    params: query,
  })
}

export function saveAdapayFrom (query) { // 支付配置信息保存
  return fetch({
    url: '/adapay/payment/setting',
    method: 'post',
    data: query
  })
}

export function getAdaPayCashList (query) { // 获取AdaPay提现列表
  return fetch({
    url: '/adapay/payment/cashlist',
    method: 'get',
    params: query,
  })
}

export function receivedOrder(order_id, query) {
  return fetch({
    url: '/order/received/' + order_id,
    method: 'post',
    data: query
  })
}

export function getAdaPayCapitalList(query) { // 获取AdaPay的资金流水
  return fetch({
    url: '/adapay/capital/list',
    method: 'get',
    params: query
  });
}

export function exportOrderReciverInfo(query) { // 获取AdaPay的资金流水
  return fetch({
    url: '/order/export/receiverinfo',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function setCreateOrderLimitSetting(query) {
  return fetch({
    url: '/trade/order/limit/setting',
    method: 'post',
    data: query
  })
}

export function getCreateOrderLimitSetting(query) { // 获取AdaPay的资金流水
  return fetch({
    url: '/trade/order/limit/setting',
    method: 'get',
    params: query
  });
}

export function getExporLabel(params) { // 获取导出列表字段
  return fetch({
    url: '/espier/export/title',
    method: 'get',
    params
  });
}

export function updateOrderInvoice(params) { // 获取AdaPay的资金流水
  return fetch({
    url: '/order/invoice',
    method: 'post',
    data: params
  });
}

export function uploadOrderInvoice (query) {
  return fetch({
    url: '/order/invoice/uploadfile',
    method: 'post',
    data: query
  })
}

export function getOrderInvoiceList (query) {
  return fetch({
    url: '/order/invoices/files',
    method: 'get',
    params: query
  })
}
